import { _get } from "@/libs/utils/helper";
import color from "@/styles/color";
import { TextField, TextFieldProps } from "@mui/material";
import React from "react";

function TextFieldInputCustom({ ...rest }: TextFieldProps) {
  return (
    <TextField
      {...rest}
      fullWidth
      variant="outlined"
      autoComplete="off"
      InputProps={{
        ..._get(rest, "InputProps", {}),
        sx: {
          borderRadius: "8px !important",
          fontWeight: 400,
          fontSize: "16px",
          lineHeight: "23px",
          color: color.black,
          background: color.gray280,
          height: { xs: "44px", lg: "56px" },
          width: "100%",
          padding: "0px",
          ..._get(rest?.InputProps, "sx", {}),
        },
      }}
      sx={{
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: "transparent",
          borderWidth: "0px !important",
        },
        "& .Mui-focused": {
          "& .MuiOutlinedInput-notchedOutline": {
            borderWidth: "0px !important",
          },
        },
        "& .Mui-disabled": {
          color: "#979797 !important",
          WebkitTextFillColor: "#979797 !important",
          bgcolor: "#FFFFFF !important",
        },
        "& input::placeholder": {
          opacity: 1,
          color: color.grey,
          fontWeight: 400,
          fontSize: { xs: "0.875rem", sm: "1rem" },
          lineHeight: "23px",
        },
        "& .MuiInputBase-input": {
          ":-webkit-autofill": {
            height: "19px",
            bgcolor: "transparent",
          },
        },
        ...rest?.sx,
      }}
      InputLabelProps={{
        shrink: true,
        sx: {
          "&.Mui-disabled": {
            color: "#979797 !important",
          },
        },
      }}
    />
  );
}

export default TextFieldInputCustom;
