"use client";
import color from "@/styles/color";
import { Divider, DividerProps } from "@mui/material";

export const DividerCustom = ({ ...rest }: DividerProps) => {
  return (
    <Divider
      {...rest}
      sx={{
        "&.MuiDivider-root": { marginTop: "0px", marginBottom: "0px" },
        borderColor: color.gray500,
        borderBottomWidth: "1px",
        ...rest?.sx,
      }}
    />
  );
};
