import { axiosDSureInstance } from "@/libs/axios/axios-dsure-instance";
import { IGetBannerContentRequest, IGetBannerContentResponse } from "./type";
import { mockHeroBanner, mockPromoBanner } from "@/libs/mocks/banner.mock";
import { ContentManagementQueryMap } from "@/libs/types/content-management-query.type";
import { ContentManagementListResponse } from "@/libs/types/content-management.type";
import { apiPaths } from "@/libs/constant/api";

export const getBannerContent = async (params: IGetBannerContentRequest) => {
  // const res: IGetBannerContentResponse = await axiosDSureInstance
  //   .get("/api/banners/content-management", { params: params })
  //   .then((response) => response.data);
  const res: IGetBannerContentResponse =
    params.type === "herobanner"
      ? { data: { image: mockHeroBanner } }
      : { data: { image: mockPromoBanner } };
  return res;
};

export const ContentManagementServices = {
  getContentManagement: async (params: ContentManagementQueryMap) => {
    const res = await axiosDSureInstance.get<ContentManagementListResponse>(
      apiPaths.content.getContentData,
      {
        params: params.payload,
      },
    );
    // .then((response) => response.data);

    return res.data;
  },
};
