import { DividerCustom } from "@/components/Divider/DividerCustom";
import { ContentManagementQueryKeyEnum } from "@/libs/enum/content-management-query-key.enum";
import { ContentManagementServices } from "@/libs/services/banners/banners.service";
import { ContentManagementQueryMap } from "@/libs/types/content-management-query.type";
import color from "@/styles/color";
import { Box, Skeleton, Typography } from "@mui/material";
import { useQuery } from "@tanstack/react-query";

interface IDealerPrivacyPolicyModalContentProps {
  contentId?: string;
}
function DealerPrivacyPolicyModalContent({ contentId }: IDealerPrivacyPolicyModalContentProps) {
  const { data: getServiceTermData, isPending: getServiceTermIsPending } = useQuery({
    queryKey: [ContentManagementQueryKeyEnum.SERVTERM, contentId],
    queryFn: () => {
      const params = new ContentManagementQueryMap();
      params.set(ContentManagementQueryKeyEnum.SERVTERM, {
        Limit: 1,
        ContentId: contentId,
      });
      return ContentManagementServices.getContentManagement(params);
    },
  });

  return (
    <Box component={"div"} sx={{ width: "100%", padding: { xs: "24px 0px", sm: "30px" } }}>
      <Box
        component={"div"}
        sx={{
          width: "100%",
          bgcolor: color.white,
          borderRadius: "16px",
          padding: { xs: "0px", sm: "32px 24px" },
          display: "flex",
          flexDirection: "column",
          gap: "16px",
        }}
      >
        {getServiceTermIsPending && (
          <Skeleton
            variant="rectangular"
            animation={"wave"}
            sx={{
              width: "100%",
              borderRadius: "16px",
              height: { xs: "50px !important", sm: "80px !important" },
            }}
          />
        )}
        <Typography
          component={"h3"}
          sx={{
            display: { xs: "none", sm: "block" },
            fontWeight: 700,
            fontSize: "24px",
            lineHeight: "34px",
            background: color.redGradient,
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
            textAlign: "center",
          }}
        >
          {getServiceTermData?.ContentManagement[0].Title}
        </Typography>
        <DividerCustom
          sx={{
            display: { xs: "none", sm: "block" },
            borderColor: color.gray170,
          }}
        />
        <Box
          component={"div"}
          sx={{
            paddingRight: { xs: "8px", xl: "32px" },
            overflowY: "scroll",
            scrollBehavior: "smooth",
            "::-webkit-scrollbar": {
              width: "8px",
              height: "8px",
              borderRadius: "4px",
              background: color.white,
            },
            "::-webkit-scrollbar-track": {
              WebkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
            },
            "::-webkit-scrollbar-thumb": {
              backgroundColor: color.gray130,
              borderRadius: "4px",
              cursor: "pointer",
            },
            height: "100%",
            maxHeight: { xs: "80vh", sm: "416px" },
          }}
        >
          {getServiceTermIsPending && (
            <Skeleton
              variant="rectangular"
              animation={"wave"}
              sx={{
                width: "100%",
                borderRadius: "16px",
                height: { xs: "300px !important", sm: "400px !important" },
              }}
            />
          )}
          <Typography
            component={"span"}
            sx={{
              fontWeight: 400,
              fontSize: { xs: "12px", xl: "14px" },
              lineHeight: { xs: "18px", xl: "21px" },
              color: color.gray400,
            }}
            dangerouslySetInnerHTML={{
              __html: getServiceTermData?.ContentManagement[0].Description ?? "",
            }}
          ></Typography>
        </Box>
      </Box>
    </Box>
  );
}

export default DealerPrivacyPolicyModalContent;
