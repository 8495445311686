export const isServer = () => {
  return typeof window === "undefined";
};

export const copyToClipboard = (text: string) => {
  navigator.clipboard.writeText(text);
};

export const phoneRegex = /(\d{3})(\d{3})(\d{4})/gi
export const toFormatMobile = (phone: string) => {
  return phone.replace(/(\d{3})(\d{3})(\d{4})/gi, '$1-$2-$3');
}

export const getFirstName = (displayName: string) => {
  return displayName.match(/^\S+/) ?.[0] || ""
}

export const regexUrl = (url: string) => {
  return "//" +url.replace(/(^\w+:|^)\/\//, '');
}

export const getFacebookFanpageByID = (facebookId: string, isResponsive: boolean = false) => {
  const width = isResponsive ? "300" : "500"
  return `https://www.facebook.com/plugins/page.php?href=${facebookId}&width=${width}&height=250&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId`
}