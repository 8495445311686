import color from "@/styles/color";
import { Select, SelectProps } from "@mui/material";
import React from "react";

interface IDropDownMuiCustomProps {
  menuItems: JSX.Element[];
}

function DropDownMuiCustom({ menuItems, ...restProps }: IDropDownMuiCustomProps & SelectProps) {
  return (
    <Select
      {...restProps}
      variant="outlined"
      labelId={`label-${restProps?.name}`}
      fullWidth
      SelectDisplayProps={{
        ...restProps?.SelectDisplayProps,
      }}
      sx={{
        borderRadius: "8px !important",
        fontWeight: 400,
        fontSize: "16px",
        lineHeight: "23px",
        color: color.black,
        background: color.gray280,
        borderColor: "#3777BC",
        height: "56px",
        width: "100%",
        padding: "0px",
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: "transparent",
          borderWidth: "0px !important",
        },
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
          borderWidth: "0px !important",
        },
        "& .Mui-disabled": {
          color: "#979797 !important",
          WebkitTextFillColor: "#979797 !important",
          bgcolor: "#FFFFFF !important",
        },
        "& input::placeholder": {
          opacity: 1,
          color: color.grey,
          fontWeight: 400,
          fontSize: "16px",
          lineHeight: "23px",
        },
        ...restProps?.sx,
      }}
    >
      {menuItems}
    </Select>
  );
}

export default DropDownMuiCustom;
