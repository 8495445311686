import { ContentManagementQueryKeyEnum } from "../enum/content-management-query-key.enum";

export type TContentBannerUploadType = "Image" | "Video";
export type TContentImageType = "PC" | "Mobile" | "Thumbnail" | "Thumbnail Mobile";

export type TContentManagementQueryToMap = {
  [ContentManagementQueryKeyEnum.ABOUTUS]: {
    ReferenceCode?: ContentManagementQueryKeyEnum;
    Length?: number;
    Limit?: number;
  };
  [ContentManagementQueryKeyEnum.ABTTSURE]: {
    ReferenceCode?: ContentManagementQueryKeyEnum;
    Length?: number;
    Limit?: number;
    ImageType: TContentImageType;
  };
  [ContentManagementQueryKeyEnum.CUSTNEWS]: {
    ReferenceCode?: ContentManagementQueryKeyEnum;
    Length?: number;
    Limit?: number;
    SearchText: string;
    SortBy: string;
    SortDirection: string;
    SearchTag: string;
    StartIndex: number;
  };
  [ContentManagementQueryKeyEnum.CUSTREVIEW]: {
    ReferenceCode?: ContentManagementQueryKeyEnum;
    Length?: number;
    Limit?: number;
  };
  [ContentManagementQueryKeyEnum.DEAL]: {
    ReferenceCode?: ContentManagementQueryKeyEnum;
    Length?: number;
    Limit?: number;
  };
  [ContentManagementQueryKeyEnum.DLRBAN]: {
    ImageType: TContentImageType;
    BannerUploadType: TContentBannerUploadType;
    Length?: number;
    ReferenceCode?: ContentManagementQueryKeyEnum;
    DealerId?: number;
    Limit?: number;
  };
  [ContentManagementQueryKeyEnum.FAQ]: {
    ReferenceCode?: ContentManagementQueryKeyEnum;
    Length?: number;
    Limit?: number;
  };
  [ContentManagementQueryKeyEnum.FINPROM]: {
    ReferenceCode?: ContentManagementQueryKeyEnum;
    Length?: number;
    Limit?: number;
    SearchText: string;
    SortBy: string;
    SortDirection: string;
    SearchTag: string;
    StartIndex: number;
  };
  [ContentManagementQueryKeyEnum.KNOWTIP]: {
    ReferenceCode?: ContentManagementQueryKeyEnum;
    Length?: number;
    Limit?: number;
    SearchText: string;
    SortBy: string;
    SortDirection: string;
    SearchTag: string;
    StartIndex: number;
  };
  [ContentManagementQueryKeyEnum.PRDCONTENT]: {
    ReferenceCode?: ContentManagementQueryKeyEnum;
    Length?: number;
    ImageType: TContentImageType;
    BrandId?: number | null | undefined;
    ModelId?: number | null | undefined;
    StartYear?: number | null | undefined;
    CapId?: number | null | undefined;
    GradeId?: number | null | undefined;
    ColorId?: number | null | undefined;
    ProvinceId?: number | null | undefined;
    Limit?: number;
  };
  [ContentManagementQueryKeyEnum.SERVTERM]: {
    ReferenceCode?: ContentManagementQueryKeyEnum;
    Length?: number;
    Limit?: number;
  };
  [ContentManagementQueryKeyEnum.TMTBAN]: {
    ReferenceCode?: ContentManagementQueryKeyEnum;
    Length?: number;
    Limit?: number;
  };
  [ContentManagementQueryKeyEnum.TMTPROM]: {
    ReferenceCode?: ContentManagementQueryKeyEnum;
    Length?: number;
    Limit?: number;
    SearchText: string;
    SortBy: string;
    SortDirection: string;
    SearchTag: string;
    StartIndex: number;
  };
  [ContentManagementQueryKeyEnum.ALLARTICLES]: {
    ReferenceCode?: ContentManagementQueryKeyEnum;
    Length?: number;
    Limit?: number;
    ContentId: string;
    SearchText?: string;
    SortBy: string;
    SortDirection: string;
    SearchTag: string;
    StartIndex: number;
  };
  [ContentManagementQueryKeyEnum.ALLPROM]: {
    ReferenceCode?: ContentManagementQueryKeyEnum;
    Length?: number;
    Limit?: number;
    ContentId: string;
    SearchText: string;
    SortBy: string;
    SortDirection: string;
    SearchTag: string;
    StartIndex: number;
  };
};

export class ContentManagementQueryMap {
  code!: ContentManagementQueryKeyEnum;
  payload: any;

  set<K extends keyof TContentManagementQueryToMap>(
    code: ContentManagementQueryKeyEnum,
    payload: TContentManagementQueryToMap[K],
  ) {
    this.code = code;
    this.payload = {
      ...payload,
      ReferenceCode: code,
    };
  }
}
