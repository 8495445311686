import React, { useMemo } from "react";
import { Box, SxProps } from "@mui/material";
import color from "@/styles/color";
import { _get } from "@/libs/utils/helper";

export interface ITabCustomRefProps {
  activeState: number;
}

type TabCustomProps = {
  activeState: number;
  onChangeActive: (state: number) => void;
  name: string;
  headerMenu?: React.ReactNode;
  tabChildren: React.ReactNode[];
  contentChildren: React.ReactNode[];
  singleContentChildren?: React.ReactNode;
  withBoxShadow?: boolean;
  getIndexTab?: (index: number) => void;
  orientation?: "vertical" | "horizontal";
  options?: {
    containerSx?: SxProps;
    tabContainerSx?: SxProps;
    tabActiveSx?: SxProps;
    tabFirstSx?: { borderRadius: string | { [key: string]: string }; borderLeft: string | { [key: string]: string } };
    tabLastSx?: { borderRadius: string | { [key: string]: string } };
    widthFitContent?: boolean;
  };
};

const TabCustom = React.forwardRef<ITabCustomRefProps, TabCustomProps>(
  (
    {
      name,
      activeState,
      tabChildren,
      contentChildren,
      options,
      headerMenu,
      orientation = "horizontal",
      onChangeActive,
      singleContentChildren,
      withBoxShadow,
    },
  ) => {
    const isVertical = useMemo(() => {
      return orientation === "vertical";
    }, [orientation]);

    const tabItemSx = useMemo(() => {
      return {
        display: "flex",
        ...(isVertical
          ? {
            maxWidth: { xl: "173px" },
            width: { xl: "173px" },
            borderRadius: { xl: "16px 0px 0px 16px !important" },
          }
          : {
            justifyContent: "center",
            alignItems: "center",
          }),
      };
    }, [isVertical]);

    return (
      <Box
        component={"div"}
        sx={{
          width: "100%",
          height: "auto",
          position: "relative",
          display: "flex",
          flexDirection: isVertical ? "row" : "column",
          borderRadius: "16px",
          ...(withBoxShadow && {
            boxShadow: "0px 4px 4px 0px #0000001A",
          }),
          ...options?.containerSx
        }}
      >
        <Box
          component={"div"}
          sx={{
            display: "flex",
            flexDirection: "column",
            position: "relative",
            // wordBreak: "break-all",
          }}
        >
          <Box
            component={"div"}
            sx={{
              display: "flex",
              flexDirection: isVertical ? "column" : "unset",
              alignItems: "flex-end",
              justifyContent: "space-between",
              // height: { xs: "40px", xl: "60px" },
              minWidth: 0,
              flexGrow: 1,
            }}
          >
            {tabChildren.map((tabEl, index) => (
              <Box
                key={`tab-item-${index}`}
                sx={{
                  cursor: "pointer",
                  width: _get(options, "widthFitContent", false) ? "auto" : "100%",
                  flex: _get(options, "widthFitContent", false) ? "1 1 auto" : "unset",
                  height: isVertical
                    ? {
                      flex: "1 50%",
                    }
                    : activeState === index
                      ? { xs: "40px", xl: "60px" }
                      : { xs: "32px", xl: "44px" },
                  transition: "all .1s ease",
                  background:
                    activeState === index
                      ? (tabEl as unknown as any)?.props?.sx[`--bgcolor-tab-active_${name}`] ||
                      `var(--bgcolor-tab-active_${name}, ${color.redGradient})`
                      : isVertical
                        ? color.white
                        : color.gray240,
                  padding: { xs: _get(options, "widthFitContent", false) ? "0px" : "16px 24px" },
                  color: color.gray400,
                  fontSize: { xs: "12px", sm: "14px", xl: "16px" },
                  ...(index < activeState || index > activeState + 1
                    ? { borderLeft: `1px solid ${color.white}` }
                    : {}),
                  ...(index === 0
                    ? _get(options, "tabFirstSx", {
                      borderRadius: "16px 0px 0px 0px",
                      borderLeft: "unset",
                    })
                    : {}),
                  ...(index === tabChildren.length - 1
                    ? _get(options, "tabLastSx", { borderRadius: "0px 16px 0px 0px" })
                    : {}),
                  ...(activeState === index
                    ? {
                      borderRadius: { xs: "8px 8px 0px 0px", xl: "16px 16px 0px 0px" },
                      ..._get(options, "tabActiveSx", {}),
                    }
                    : { ..._get(options, "tabContainerSx", {}) }),
                  ...tabItemSx,
                }}
                onClick={() => onChangeActive(index)}
              >
                {tabEl}
              </Box>
            ))}
          </Box>
        </Box>
        {headerMenu && <>{headerMenu}</>}
        <Box sx={{ flexGrow: 1 }}>
          {contentChildren.map((contentEl, index) => (
            <Box
              key={`content-item-${index + 1}`}
              component={"div"}
              id={`${index}`}
              sx={{
                background: "transparent",
                width: "100%",
                height: "100%",
                display: activeState === index ? "block" : "none",
              }}
            >
              {contentEl}
            </Box>
          ))}
          {singleContentChildren && <>{singleContentChildren}</>}
        </Box>
      </Box>
    );
  },
);

TabCustom.displayName = "TabCustom";

export default TabCustom;
