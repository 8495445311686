"use client";
import color from "@/styles/color";
import { Button, ButtonProps } from "@mui/material";
import React from "react";

interface IButtonCustomProps extends ButtonProps {
  label: string | JSX.Element;
}

function ButtonCustom({ label, ...rest }: IButtonCustomProps) {
  return (
    <Button
      variant="contained"
      {...rest}
      sx={{
        width: "100%",
        height: "40px",
        borderRadius: "30px",
        padding: "16px 0px",
        textTransform: 'none',
        background: color.redGradient,
        fontWeight: 600,
        fontSize: { xs: "10px", sm: "12px", xl: "14px" },
        lineHeight: { xs: "21px", sm: "24px" },
        color: color.white,
        boxShadow: "unset",
        ":hover": {
          boxShadow: "unset",
          opacity: 0.9,
        },
        ...rest?.sx,
      }}
    >
      {label}
    </Button>
  );
}

export default ButtonCustom;
