import { useMediaQuery, useTheme } from "@mui/material";

export function useReadScreen() {
  const theme = useTheme();
  const isMoreThanXs = useMediaQuery(theme.breakpoints.up("xs"));
  const isLessThanXs = useMediaQuery(theme.breakpoints.down("xs"));

  const isMoreThanSm = useMediaQuery(theme.breakpoints.up("sm"));
  const isLessThanSm = useMediaQuery(theme.breakpoints.down("sm"));

  const isMoreThanMd = useMediaQuery(theme.breakpoints.up("md"));
  const isLessThanMd = useMediaQuery(theme.breakpoints.down("md"));

  const isMoreThanLg = useMediaQuery(theme.breakpoints.up("lg"));
  const isLessThanLg = useMediaQuery(theme.breakpoints.down("lg"));

  const isMoreThanXl = useMediaQuery(theme.breakpoints.up("xl"));
  const isLessThanXl = useMediaQuery(theme.breakpoints.down("xl"));

  const isBetweenXsAndSm = useMediaQuery(theme.breakpoints.between("xs", "sm"));
  const isBetweenXsAndLg = useMediaQuery(theme.breakpoints.between("xs", "lg"));
  const isBetweenSmAndLg = useMediaQuery(theme.breakpoints.between("sm", "lg"));
  const isBetweenSmAndMd = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const isBetweenLgAndxl = useMediaQuery(theme.breakpoints.between("lg", "xl"));

  const isHeightMoreThanMd = useMediaQuery('(min-height: 800px)');
  const isHeightMoreThanXl = useMediaQuery('(min-height: 1200px)');
  

  return {
    isLessThanLg,
    isLessThanMd,
    isLessThanSm,
    isLessThanXl,
    isLessThanXs,

    isMoreThanLg,
    isMoreThanMd,
    isMoreThanSm,
    isMoreThanXl,
    isMoreThanXs,

    isBetweenXsAndSm,
    isBetweenXsAndLg,
    isBetweenSmAndLg,
    isBetweenLgAndxl, 
    isBetweenSmAndMd,

    isHeightMoreThanXl,
    isHeightMoreThanMd
  };
}
