import { Box, BoxProps } from "@mui/material";
import React from "react";
import Image from "next/image";

interface IImageCustomProps extends BoxProps {
  imageSrc: string;
  imageAlt?: string;
  options?: Omit<
    React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>,
    "height" | "width" | "loading" | "ref" | "alt" | "src" | "srcSet"
  > & {
    width?: number | `${number}` | undefined;
    height?: number | `${number}` | undefined;
    fill?: boolean | undefined;
    quality?: number | `${number}` | undefined;
    priority?: boolean | undefined;
    overrideSrc?: string | undefined;
    layout?: string | undefined;
    objectFit?: string | undefined;
    objectPosition?: string | undefined;
    lazyBoundary?: string | undefined;
    loading?: "eager" | "lazy" | undefined;
  } & React.RefAttributes<HTMLImageElement | null>;
}

function ImageCustom({ imageSrc, imageAlt, options, ...rest }: IImageCustomProps) {
  return (
    <Box
      {...rest}
      sx={{
        img: {
          objectFit: "cover",
          width: "100%",
          height: "auto",
        },
        ...rest?.sx,
      }}
    >
      <Image
        src={imageSrc}
        alt={imageAlt || "Toyota Image"}
        width={0}
        height={0}
        sizes="50%"
        style={{ width: "100%", height: "auto" }}
        {...options}
        loading={!options?.priority ? "lazy" : undefined}
      />
    </Box>
  );
}

export default ImageCustom;
