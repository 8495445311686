"use client";
import color from "@/styles/color";
import { Checkbox, CheckboxProps } from "@mui/material";
import React from "react";
import IconLoader from "../IconLoader";

interface ICheckboxCustomProps extends CheckboxProps {
  iconSize?: string;
  name?: string;
}
const label = { inputProps: { "aria-label": "Checkbox filter" } };

function CheckboxCustom({ iconSize = "30px", name, ...rest }: ICheckboxCustomProps) {
  return (
    <Checkbox
      {...rest}
      {...label}
      sx={{
        width: "30px",
        height: "30px",
        color: color.gray200,
        "&.Mui-checked": {
          background: color.white,
          color: color.white,
          borderRadius: "4px",
        },
        ...rest?.sx,
      }}
      checkedIcon={
        <IconLoader iconName="CheckboxCheckedGradientIcon" sx={{ fontSize: iconSize }} />
      }
    />
  );
}

export default CheckboxCustom;
