export enum ContentManagementQueryKeyEnum {
  ABOUTUS = "ABOUTUS",
  ABTTSURE = "ABTTSURE",
  CUSTNEWS = "CUSTNEWS",
  DLRBAN = "DLRBAN",
  FINPROM = "FINPROM",
  KNOWTIP = "KNOWTIP",
  PRDCONTENT = "PRDCONTENT",
  SERVTERM = "SERVTERM",
  TMTBAN = "TMTBAN",
  TMTPROM = "TMTPROM",
  DEAL = "DEAL",
  FAQ = "FAQ",
  CUSTREVIEW = "CUSTREVIEW",
  ALLARTICLES = "ALLARTICLES",
  DLRPROM = "DLRPROM",
  ALLPROM = "ALLPROM",
}
