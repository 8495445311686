import { VehicleDetail } from "@/libs/types/vehicle.type";
import { create } from "zustand";

type VehicleStore = {
  vehicleDetail: Nullable<VehicleDetail>;
  setVehicleDetail: (vehicleDetail: VehicleDetail) => void;

  showFloatingHeader: boolean;
  setShowFloatingHeader: (showFloatingHeader: boolean) => void;

  showFloatingLoadAndInstallment: boolean;
  setShowFloatingLoadAndInstallment: (showFloatingLoadAndInstallment: boolean) => void;
};

export const useVehicleStore = create<VehicleStore>((set) => ({
  vehicleDetail: null,
  setVehicleDetail: (vehicleDetail: VehicleDetail) => {
    set({ vehicleDetail });
  },

  showFloatingHeader: false,
  setShowFloatingHeader: (showFloatingHeader: boolean) => {
    set({ showFloatingHeader });
  },

  showFloatingLoadAndInstallment: false,
  setShowFloatingLoadAndInstallment: (showFloatingLoadAndInstallment: boolean) => {
    set({ showFloatingLoadAndInstallment });
  },
}));
