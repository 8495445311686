import color from "@/styles/color";
import { Box, Drawer, DrawerProps, IconButton, Stack, SxProps, Typography } from "@mui/material";
import React from "react";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import IconLoader from "../IconLoader";

interface IDrawerBottomCustomProps extends DrawerProps {
  labelName?: string;
  labelSx?: SxProps;
  headerSx?: SxProps;
  isOpen: boolean;
  showBackButton?: boolean;
  onClickBack?: () => void;
  onCloseDrawer: () => void;
}

function DrawerBottomCustom({
  labelName,
  labelSx,
  headerSx,
  isOpen,
  showBackButton,
  onClickBack,
  onCloseDrawer,
  children,
  ...rest
}: IDrawerBottomCustomProps) {
  return (
    <Drawer
      {...rest}
      anchor={"bottom"}
      open={isOpen}
      onClose={onCloseDrawer}
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 20,
        display: { xs: "block", sm: "none" },
        "& .MuiDrawer-paper": {
          maxWidth: "90vh",
          minWidth: "100%",
          width: "100%",
          borderRadius: "24px 24px 0px 0px",
          padding: "24px 16px",
        },
        ...rest?.sx,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            backgroundColor: "transparent",
            alignItems: "center",
            justifyContent: "space-between",
            ...headerSx,
          }}
        >
          <Stack
            sx={{
              alignItems: "center",
              flexDirection: "row",
              gap: 1,
            }}
          >
            {showBackButton && (
              <IconButton onClick={onClickBack} sx={{ padding: 0 }}>
                <IconLoader
                  iconName="ArrowBackIcon"
                  sx={{ color: color.gray190, fontSize: "18px" }}
                />
              </IconButton>
            )}
            <Typography
              sx={{
                fontWeight: 600,
                fontSize: "16px",
                lineHeight: "30px",
                width: "100%",
                color: color.black200,
                textAlign: "start",
                ...labelSx,
              }}
            >
              {labelName}
            </Typography>
          </Stack>
          <IconButton onClick={onCloseDrawer}>
            <CloseRoundedIcon sx={{ fontSize: "30px", color: color.black300 }} />
          </IconButton>
        </Box>
        {children}
      </Box>
    </Drawer>
  );
}

export default DrawerBottomCustom;
