export const PagePath = {
  home: "/home",
  compareCar: "/home/compare-car",
  dealerList: "/dealerdetails/dealerlist",
  dealerDetailList: "/dealerdetails/dealerdetails",
  articleKnowledge: "/home/knowledge",
  articleNews: "/home/news",
  articlePromotion: "/promotions/promotionlist",
  vehicleDetail: "/home/product",
  wantToBuy: "/home/used-car",
  contentDetail: "/contentdetail/contentdetailsui",
  financialCalculator: "/promotions/financecalculator",
  aboutUsList: "/home/about-us",
  faqList: "/home/faq",
  wishListAnonymous: "/login/wishlist",
  wishlistAuth: "/login/memberprofile/3",
  privacy_policy: "/privacy-policy"
};
